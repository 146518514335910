<script setup>
import PrimaryButton from "@/Components/PrimaryButton.vue";</script>

<template>
    <div v-show="showPopup"
         class="cookie-consent-popup w-full rounded shadow-top-lg bg-kvpOrangeDarker py-3 px-5 flex justify-center">
        <div class="md:w-1/2">
            <h4 class="text-kvpText">Klein Vossen Park gebruikt cookies om uw surfervaring te verbeteren.</h4>
<!--            Desktop tekst-->
            <p class="text-kvpWhite  max-sm:hidden">
                Sommige cookies zijn noodzakelijk om de website optimaal te laten functioneren. Andere cookies zijn
                optioneel en worden gebruikt om uw surfervaring op deze website te verbeteren. Als u op ‘Alle cookies
                aanvaarden’ klikt, aanvaardt u ook de optionele cookies. Meer info leest u in het <a class="text-kvpText underline" :href="route('legal.privacy_beleid')">privacy-</a> en
                <a class="text-kvpText underline" :href="route('legal.cookieverklaring')">cookiebeleid</a>.
            </p>
<!--            Mobile tekst-->
            <p class="text-kvpWhite hidden max-sm:block">
                Als u op ‘Alle cookies
                aanvaarden’ klikt, aanvaardt u ook de optionele cookies. Meer info leest u in het <a class="text-kvpText underline" :href="route('legal.privacy_beleid')">privacy-</a> en
                <a class="text-kvpText underline" :href="route('legal.cookieverklaring')">cookiebeleid</a>.
            </p>
            <div class="md:flex md:flex-row md:gap-4">
                <PrimaryButton @click="accept">Alle cookies aanvaarden</PrimaryButton>
                <PrimaryButton @click="reject">Enkel noodzakelijke cookies aanvaarden</PrimaryButton>
            </div>
        </div>
    </div>
</template>

<script>
import {revokeGtag, setGtag} from "@/gtag";

export default {
    name: "CookieConsent",
    data() {
        return {
            showPopup: true,
            analytics: false
        }
    },
    mounted() {
        if (this.$cookies.get('cookie-consent') === 'true') {
            // set the cookie again so it stays longer
            this.$cookies.set('cookie-consent', 'true', '1Y');
            this.showPopup = false;
        }
        // Check if analytics cookies are set to true or false and accept or reject accordingly
        if (this.$cookies.get('cookie-consent-analytics') === 'true') {
            this.$cookies.set('cookie-consent-analytics', 'true', '1Y');
            this.accept()
        } else if (this.$cookies.get('cookie-consent-analytics') === 'false') {
            this.reject()
            this.$cookies.set('cookie-consent-analytics', 'false', '1Y');
        }

    },
    methods: {
        accept() {
            this.showPopup = false;
            setGtag();
            fbq('init', '159302676989635');
            fbq('track', 'PageView');
            fbq('consent', 'grant');

            // Set cookies using js-cookie
            this.$cookies.set('cookie-consent', 'true', '1Y');
            this.$cookies.set('cookie-consent-analytics', 'true', '1Y');
        },
        reject() {
            this.showPopup = false;
            revokeGtag()
            fbq('init', '159302676989635');
            fbq('track', 'PageView');
            fbq('consent', 'revoke');

            // Set cookies
            this.$cookies.set('cookie-consent', 'true', '1Y');
            this.$cookies.set('cookie-consent-analytics', 'true', '1Y');
        }
    },
}
</script>

<style scoped>
.cookie-consent-popup {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    text-align: center;
}
</style>
