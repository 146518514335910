<template class="mb-0">
    <div class="footerWrap01">
        <footer class="row">
            <div class="row flex-col md:flex-row justify-center">
                <div class="large-12 columns ">
                    <div class="panel">
                        <div class="flex md:flex-row flex-col flex-wrap">
                            <h3 class="subheader my-0 flex-1">Klein Vossen Park</h3>
                            <div class="flex flex-row"><a
                                class=" mt-2 h-3/4 w-1/2 md:w-fit text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 mr-2 mb-2"
                                href="https://www.facebook.com/KleinVossenParkMontenaken" rel="noopener"
                                target="_blank">
                                <svg aria-hidden="true" class="w-4 h-4 mr-2 -ml-1" data-icon="facebook-f"
                                     data-prefix="fab"
                                     focusable="false" role="img" viewBox="0 0 320 512"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"
                                        fill="currentColor"></path>
                                </svg>
                                Facebook
                            </a>
                                <a class=" mt-2 h-3/4 w-1/2 md:w-fit text-kvpWhite bg-instagram hover:bg-instagram/90 focus:ring-4 focus:outline-none focus:ring-instagram/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-instagram/55 mr-2 mb-2"
                                   href="https://www.instagram.com/kleinvossenpark/" rel="noopener"
                                   target="_blank">
                                    <svg
                                        class="w-4 h-4 mr-2 -ml-1"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                                    </svg>
                                    Instagram
                                </a></div>
                        </div>
                        <p class="my-0">Groenplaats 4, 3890 Montenaken</p>
                        <p class="my-0">Tel: 0477 36 36 63</p>
                        <p class="mt-0">E-mail: <a href="mailto:info@kleinvossenpark.be"><u>info@kleinvossenpark.be</u></a>
                        </p>

                        <p class="mb-0">BTW: 0641 53 35 51</p>
                        <p class="mt-0">Bankrelatie: BE26 0013 0083 1129 of 001-3008311-29</p>

                        <div class="mb-0">
                            <ul class="flex md:scale-75 md:flex-row md:gap-4 flex-col mb-0 items-center">
                                <li class="no-list"><a :href="route('legal.algemene_voorwaarden')">Algemene
                                    voorwaarden</a></li>
                                <li class="no-list">-</li>
                                <li class="no-list"><a :href="route('legal.privacy_beleid')">Privacy beleid</a></li>
                                <li class="no-list">-</li>
                                <li class="no-list"><a :href="route('legal.cookieverklaring')">Cookieverklaring</a></li>
                                <li class="no-list">-</li>
                                <li class="no-list"><a href="#" @click="showCookies()">Cookie voorkeuren aanpassen</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--                    Make a div that is situated in the right corner that contains an p and an image-->
                <div class="flex flex-row gap-4 justify-center items-center mr-10 mb-5">
                    <a href="https://vlaanderen.be" rel="noreferrer noopener" target="_blank">
                        <img alt="Logo Vlaamse Overheid" class="h-16 "
                             src="/public/storage/Afbeeldingen/Icons/Vlaanderen verbeelding werkt_vol_geel.png">
                    </a>
                    <a href="https://iedereenverdientvakantie.be" rel="noreferrer noopener" target="_blank">
                        <img alt="Logo Iedereen Verdient Vakantie" class="w-16 h-16 "
                             src="/public/storage/Afbeeldingen/Icons/Logo_Iedereen_Verdient_Vakantie_Vlinder.png">
                    </a>
                </div>
                </div>

            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: "Footer",
    methods: {
        showCookies() {
            //Remove the cookies
            this.$cookies.remove("cookie-consent")
            this.$cookies.remove("cookie-consent-analytics")
            //     Reload the page
            location.reload()
        }
    }
}
</script>

<style scoped>
a {
    color: whitesmoke;
    text-decoration: underline;
}

.no-list {
    list-style: none !important;
}
</style>
