var dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

gtag('js', new Date());

function setGtag() {
    gtag('config', 'G-GKKFLMVTX5', {'anonymize_ip': true});
    gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
    });
}

function revokeGtag() {
    gtag('config', 'G-GKKFLMVTX5', {'anonymize_ip': true});
    gtag('consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
    });
}

function gtagEvent(action, value) {
    gtag('event', action, value);
}

function gtagSet(config) {
    gtag('set', config);
}

export {setGtag, revokeGtag, gtagEvent, gtagSet};
